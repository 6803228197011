
#boxContainer{
    display:flex;
    flex-wrap: wrap;
    position: relative;
}

#readyBox {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#clearBox {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}
#clearBox > * {
    font-size: 30px;
    color: white;
}

#readyBox > button{
    width: 100px;
    height: 30px;
    border-radius: 10px;
    border: 2px solid red;
    font-weight: bold;
    cursor: pointer;
}
#readyBox > button:active{
    background-color: blue;
}

.outerBox {
    display: flex;
    flex-direction: column;
}

.outerBox > .innerBox {
    flex-grow: 1;
    flex-basis: 0px;
    position: relative;
    justify-content: center;
    display: flex;
    align-items: center;
}

.outerBox > .innerBox > .arrow{
    position: absolute;
    cursor: pointer;
}
.outerBox > .innerBox > .arrow:hover{
    color: red;
}


#final{
    background: orange;
}